import * as React from "react"
import { Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import {
  ForCardNoLinks,
  FooterRecCard,
  IndustriesStatic,
} from "../components/resources"
import "../css/_section-service-img.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/service/",
    label: "Service",
  },
]

const serviceCards = [
  {
    title: "24/7/365",
    LinkUr: "/",
    rec: "../images/service/dateLogo.png",
    content:
      "What Vigilant means when we promise 24/7/365 service: Our SOC is always on and there is human intelligence always assisting our state-of-art automated and artificial intelligences. You can rest because we never do.",
  },
  {
    title: "ON-SHORE TEAMS",
    LinkUr: "/",
    rec: "../images/service/teamLogo.png",
    content:
      "Because they need to be the most trusted and dedicated people you partner with, Vigilant’s 24/7/365 Security Operations Center team is 100% U.S. based – so you can travel to their location, easily talk to them. In fact, every Client has a scheduled monthly meeting with their Analyst as a white glove touchpoint. It’s all part of the relationship and service that sets Vigilant apart.",
  },
  {
    title: "DISCRETION WITHOUT COMPROMISE",
    LinkUr: "/",
    rec: "../images/service/compLogo.png",
    content:
      "Our service comes with a security promise. Part of that promise is we will never reveal who our clients are, nor sell your data or identity. The other part is Vigilant is never for sale. The promises and policies of today will still be true tomorrow.",
  },
]

const resourcesList = [
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMNDR",
    subtitle: " Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const ServiceVig = () => (
  <Layout>
    <Seo title="Our Services" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top grid--bg --for-it-ops-3 ">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>THE VIGILANT TEAM</h1>
            <h4>YOUR WHOLE TEAM SOLUTION</h4>
            <p className="content">
              Vigilant continuously deploys our entire integrated team of
              specialists to collaboratively protect and defend alongside your
              existing force. Together, we protect your people, production and
              profits.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  meet our team
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </Link>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>

    <section className="section-cards-3--for-card">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul className="card-feature-list no-link">
              {serviceCards.map(data => {
                return (
                  <ForCardNoLinks
                    altTxt={data.title}
                    title={data.title}
                    imgSrc={data.rec}
                    content={data.content}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="section-service-img">
      <div className="container">
        <div className="row">
          <div className="section-col-1 col-sm-6">
            <h2>REAL-TIME ENGAGEMENT</h2>
            <h6>SHARED INTEL AND WEAPONS</h6>
            <p>
              The CyberDNA® Command and Control Center is the real-time stage of
              the Vigilant service.
            </p>
            <p>
              Have a total security operations experience when you share the
              intel and weapons of cyber-war with the Vigilant team of
              Sentinels, Analysts and Hunters through the CyberDNA® Command and
              Control Center.
            </p>
            <Link to="/the-platform/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  learn more
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </Link>
          </div>
          <div className="section-col-2 col-sm-6 pt-5">
            <img
              src="../images/service/viggy.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-copy-img-l">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <img
              src="../images/service/vigCircle.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Logo Holder"
              className="img-fluid"
            />
          </div>
          <div className="section-col-2">
            <div className="side-binder">
              <h6>THE POWER OF JOINING FORCES</h6>
              <h3>WHOLE-TEAM SERVICE APPROACH</h3>
            </div>
            <p>
              Vigilant’s collaborative team approach to cybersecurity is unlike
              any other. We fend off cyberfoes with advanced technologies that
              proactively detect and protect but there is a bigger difference
              and it begins with service and partnership. Some providers promise
              a white-gloved concierge model, but your Vigilant Team isn’t
              afraid of getting our hands dirty – as a dedicated group of
              specialists fighting shoiulder-to-shoulder right along with you
              and your team. For us, cyberwar is not a vacation at the Ritz.
            </p>
            <p className="p-quote white">
              “On ground of intersecting highways, join hands with your allies.”{" "}
              <span className="p-quote--author white">- The Art of War</span>
            </p>
            <p>
              By joining forces with Vigilant you experience communication and
              collaboration with different voices, each true to the moment. Our
              onboarding team are experts of integration. Our analysts are
              experts in explanation. Our hunters are experts in extermination.
              From the CEO on down, Vigilant becomes a part of your team and you
              join ours. At this time of unprecedented “intersecting highways”,
              trustworthy allies need to stay closer than ever.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  get started
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section-blocks-6">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="side-binder">
              <h4>INDUSTRIES</h4>
              <h3>SERVICE</h3>
            </div>
          </div>
          <div className="section-col-2">
            <IndustriesStatic />
          </div>
        </div>
      </div>
    </section>
    <section className="section-client-quote">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <p>
                "I have been a Vigilant customer for over a year. I have been
                extremely happy with the services they provide because I can
                sleep at night knowing I am not getting to get breached. I know
                the Vigilant team is truly best in the business and are looking
                out for my business’ best interests. They truly have been an
                extension of my organization that is irreplaceable and have made
                things easier to grow the organization.”{" "}
              </p>
              <hr />
              <h6>- HEALTHCARE CLIENT</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="grid--bg --for-it-ops-2">
      <section className="section-resources">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>RESOURCES</h3>
            </div>
            <div className="section-col-2">
              <ul className="resources-list">
                {resourcesList.map(data => {
                  return (
                    <FooterRecCard
                      altTxt={data.title}
                      title={data.title}
                      subTitle={data.subtitle}
                      recCat={data.cat}
                      LinkUrl={data.LinkUr}
                      key={uuidv4()}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default ServiceVig
